

































































































































































































import { Component, Inject, Vue } from 'vue-property-decorator'
import { StudentController, LoginController } from '@/services/request.service'
import VueCropper from 'vue-cropperjs'
import debounce from 'lodash/debounce'
import 'cropperjs/dist/cropper.css'
// import { fileUploader } from '@/services/qiniu.service'

@Component({
  components: {
    VueCropper,
  },
})
export default class Personal extends Vue {
  private isMajor: boolean = false
  private timer: any = null
  private verCodeTimer = 0
  private verCodeLoading: boolean = false
  private parentInfo: any = {}
  private personalInfoForm: any
  private data: any = []
  private personalInfo: any = {}
  private loading: boolean = false
  private editorVisible: boolean = false
  private editorType: string = ''
  private addParentFlag = true
  private mode = 'view'
  private saveLoadingSecret: boolean = false
  private saveInfoLoading: boolean = false
  private passwordForm: any
  private visibleSecret: boolean = false

  private beforeCreate(): void {
    this.personalInfoForm = this.$form.createForm(this)
    this.passwordForm = this.$form.createForm(this)
  }

  private created(): void {
    this.$nextTick(() => {
      this.getData()
    })
  }

  private resendCountDown(): void {
    this.timer = setInterval(() => {
      if (this.verCodeTimer === 0) {
        clearInterval(this.timer)
        this.timer = null
      } else {
        this.verCodeTimer = this.verCodeTimer - 1
      }
    }, 1000)
  }

  private validateName(rule, value, callback): void {
    const form = this.personalInfoForm
    const name = form.getFieldValue('name')
    const enName = form.getFieldValue('enName')
    if (enName || name) {
      callback()
    } else {
      callback(this.$t('personal.nameValidateTip'))
    }
  }

  private edit(): void {
    this.mode = 'edit'
    this.$nextTick(() => {
      this.personalInfoForm.setFieldsValue(this.personalInfo)
    })
  }

  private modifyPassword(): void {
    this.saveLoadingSecret = true
    const form = this.passwordForm
    form.validateFields((err, values) => {
      if (err) {
        this.saveLoadingSecret = false
        return
      } else {
        const request = {} as any
        request.oldPassword = values.password
        request.newPassword = values.newPassword
        StudentController.updatePassword(request)
          .then(res => {
            if (!res.data.success) {
              form.setFields({
                ['password']: {
                  errors: [{ message: this.$t('personal.passwordLimit'), field: 'password' }],
                },
              })
            } else {
              this.$message.success(this.$tc('common.saveSuccess'))
              this.visibleSecret = false
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveLoadingSecret = false
          })
      }
    })
  }

  private editCancel(): void {
    this.mode = 'view'
  }

  private saveInfo(): void {
    this.saveInfoLoading = true
    const form = this.personalInfoForm
    form.validateFields((err: any, values: any) => {
      if (err) {
        this.saveInfoLoading = false
        return
      } else {
        values.parentId = this.personalInfo.parentId
        StudentController.updateUserInfo(values)
          .then(res => {
            if (res.data.success) {
              this.saveInfoLoading = false
              this.$message.success(this.$tc('common.saveSuccess'))
              this.getData()
              this.mode = 'view'
            } else {
              this.$message.error(res.data.resMsg)
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveInfoLoading = false
          })
      }
    })
  }

  private cancel(): void {
    this.visibleSecret = false
  }

  private modifySecret(): void {
    this.visibleSecret = true
  }

  private getUserInfo(): void {
    StudentController.getUserInfo()
      .then(res => {
        this.personalInfo = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(): void {
    this.getUserInfo()
  }

  private validateToNextPassword(rule, value, callback): void {
    const form = this.passwordForm
    if (/^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/.test(value)) {
      callback()
    } else {
      callback(this.$t('personal.passwordUnLaw'))
    }
  }

  private compareToFirstPassword(rule, value, callback): void {
    const form = this.passwordForm
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(this.$t('personal.wrongPassword'))
    } else {
      callback()
    }
  }
}
